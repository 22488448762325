<template>
  <div class="page-container">
    <div class="page-content">
      <progress-step :step="page.step"></progress-step>
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">How did You Hear about Us?</div>
        </v-card-text>
        <v-card-text>
          <v-list
            subheader
            two-line
            flat
          >
            <v-list-item-group
              v-model="settings"
            >
              <v-list-item v-for="(item, index) in list" :key=index>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.option }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <div class="page-action">
      <v-btn
        large
        block
        color="primary"
        @click="next"
      >NEXT</v-btn>
    </div>

    <v-snackbar
      color="error"
      v-model="snackbar"
      multi-line
    >
      {{message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import ProgressStep from '../../components/progress/ProgressStep'

export default {
  components: { ProgressStep },
  data () {
    return {
      settings: [],
      list: [
        { option: 'Social media' },
        { option: 'Website' },
        { option: 'Advertisement' },
        { option: 'A friend or family member' },
        { option: 'Others' }
      ],
      picked: '',
      message: 'Please choose one. Thank you',
      snackbar: false,
      disabled: true,
      progress: 11,
      step: 'bank'
    }
  },
  methods: {
    next () {
      this.$router.push('/authentication/signature')
    }
  },
  created () {
    this.$store.commit('setPageCount', 12)
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
  }
}
</script>
